<template>
  <div class="row row-spacing mt-4">
    <div class="col-md-12">
      <div>
        <div v-if="history.history.length > 0">
          <div v-for="change in history.history" :key="change.version">
            <div v-if="change.hasChanged">
              <div class="card mb-3 border-primary">
                <div
                  class="card-header"
                  style="border-bottom: 1px solid rgba(0, 0, 0, 0.125)"
                >
                  <h4>{{ change.username }}</h4>
                </div>
                <div class="card-body">
                    <div
                      v-for="fieldChange in change.fields"
                      :key="fieldChange.name"
                    >
                      <div v-if="fieldChange.changed">
                        <p
                          v-if="
                            fieldChange.name == 'version' ||
                            fieldChange.name == 'modified'
                          "
                        ></p>
                        <p v-else>
                          <b>{{ fieldChange.label }}</b> was changed to
                          <b style="color: dodgerblue">{{ fieldChange.value }}</b>
                        </p>
                      </div>
                    </div>
                  <p style="color: green" v-if="change.type == 'CREATE'">
                    Created
                  </p>
                  <p style="color: orangered" v-if="change.type == 'DELETE'">
                    Delete
                  </p>
                </div>
                <div class="card-footer">
                  <div class="text-sm text-muted">{{ change.created }}</div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="card-body" v-else>No client history to display</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      history: {
        history: [],
      },
      nochanges: true
    };
  },
  mounted() {
    this.$http
      .get(
        this.$config.aimsAPI +
          "clients/" +
          this.$route.params.clientNumber +
          "/history"
      )
      .then(
        (response) => {
          response.data.history = response.data.history.reverse();
          this.history = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Client History", error);
        }
      );
  },
};
</script>

<style scoped>
.card-header {
  padding-bottom: 0px;
} 
</style>
